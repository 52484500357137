import * as React from 'react';
import Box from '@mui/material/Box';
import AppBar from '../components/AppBar';
import Toolbar from '../components/Toolbar';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import {FaCaretDown, FaPhoneAlt} from "react-icons/fa";
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import configuration from './../../configuration';

const pages = [
    {key: 'pricing', name: 'Ценоразпис'},
    {key: 'contact', name: 'Контакти'},
];

const menuButtonStyle = {
    my: 2,
    color: 'white',
    display: 'block',
    '&:hover': {
        bgcolor: '#626262',
    },
}

function AppAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const logo = '/images/beauty_room_logo_head.png';

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const phoneNumber = 'tel:' + configuration.phone;

  return (
      <AppBar position="sticky">
          <Container maxWidth="xl">
              <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box
                  href={'/'}
                  component="a"
                  sx={{mr: 10, display: { xs: 'none', md: 'flex' }}}
                >
                  <Box
                    component="img"
                    src={logo}
                    sx={{ maxWidth: 200 }}
                    alt="Beauty Room Logo"
                  />
                </Box>

                <Box sx={{ maxWidth: '48px', flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                    >
                      {configuration.all_pages.map((page) => (
                        <MenuItem
                          key={page.key}
                          onClick={handleCloseNavMenu}
                          href={page.path}
                          component="a"
                        >
                          <Typography textAlign="center">{page.name}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                </Box>

                <Box
                  href={'/'}
                  component="a"
                  sx={{display: { xs: 'flex', md: 'none' }}}
                >
                  <Box
                    component="img"
                    src={logo}
                    sx={{ maxWidth: 200 }}
                    alt="Beauty Room Logo"
                  />
                </Box>

                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

                  <Button
                    key={'index'}
                    onClick={handleCloseNavMenu}
                    sx={menuButtonStyle}
                    href={'/'}
                    component="a"
                  >
                    Начало
                  </Button>

                  <Button
                    key={'about'}
                    onClick={handleCloseNavMenu}
                    sx={menuButtonStyle}
                    href={'/about'}
                    component="a"
                  >
                    За нас
                  </Button>

                  <Button
                    key={'services'}
                    ref={anchorRef}
                    sx={menuButtonStyle}
                    onClick={handleToggle}
                    aria-controls={open ? 'composition-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                  >
                    Услуги <FaCaretDown />
                  </Button>
                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem onClick={handleClose} href={'/service/nails'} component="a">Маникюр и Педикюр</MenuItem>
                              <MenuItem onClick={handleClose} href={'/service/hair'} component="a">Фризьорски услуги</MenuItem>
                              <MenuItem onClick={handleClose} href={'/service/cosmetics'} component="a">Козметични услуги</MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>

                  {pages.map((page) => (
                      <Button
                          key={page.key}
                          onClick={handleCloseNavMenu}
                          sx={menuButtonStyle}
                          href={'/' + page.key}
                          component="a"
                      >
                          {page.name}
                      </Button>
                  ))}
              </Box>

              <Button
                color="secondary"
                variant="contained"
                size="large"
                component="a"
                href={phoneNumber}
                sx={{ minWidth: '200', display: {'xs': 'none', md: 'flex'} }}
              >
                {configuration.phone_read}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                size="large"
                component="a"
                href={phoneNumber}
                sx={{ display: {'xs': 'flex', md: 'none'} }}
              >
                <FaPhoneAlt />
              </Button>
              </Toolbar>
          </Container>
      </AppBar>
  );
}

export default AppAppBar;
