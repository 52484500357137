import React, {lazy} from 'react';
import AppFooter from './modules/views/AppFooter';
import AppAppBar from './modules/views/AppAppBar';
import withRoot from './modules/withRoot';
import { BrowserRouter, Routes, Route } from "react-router-dom";

const App = lazy(() => import('./routes/App'));
const Pricing = lazy(() => import('./routes/Pricing'));
const Contact = lazy(() => import('./routes/Contact'));
const About = lazy(() => import('./routes/About'));
const ServiceNails = lazy(() => import('./routes/ServiceNails'));
const ServiceHair = lazy(() => import('./routes/ServiceHair'));
const ServiceCosmetics = lazy(() => import('./routes/ServiceCosmetics'));

function Index() {
  return (
    <React.Fragment>
        <BrowserRouter>
            <AppAppBar />

            <Routes>
                <Route path="/" element={<App />} />
                <Route path="pricing" element={<Pricing />} />
                <Route path="about" element={<About />} />
                <Route path="contact" element={<Contact />} />
                <Route path="service/nails" element={<ServiceNails />} />
                <Route path="service/hair" element={<ServiceHair />} />
                <Route path="service/cosmetics" element={<ServiceCosmetics />} />
            </Routes>

            <AppFooter />
        </BrowserRouter>

    </React.Fragment>
  );
}

export default withRoot(Index);
