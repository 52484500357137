import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import {FaFacebook, FaInstagram, FaMapMarkerAlt, FaPhoneAlt} from "react-icons/fa";
import configuration from './../../configuration';

function Copyright() {
  return (
    <React.Fragment>
      {'© '}
      <Link color="inherit" href="https://beautyroombg.com/">
        BR Beauty Room
      </Link>{' '}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

const iconStyle = {
  width: 48,
  height: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'warning.main',
  mr: 1,
  '&:hover': {
    bgcolor: 'warning.dark',
  },
  color: 'primary.main',
  float: 'left'
};

export default function AppFooter() {
  const logo = '/images/logo_dark.png';

  return (
    <Typography
      component="footer"
      sx={{ display: 'flex', bgcolor: 'secondary.light' }}
    >
      <Container sx={{ my: 8, display: 'flex' }}>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={4} md={3}>
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              spacing={2}
            >
              <Grid item xs={12} md={12}>
                <Box
                  href={'/'}
                  component="a"
                >
                  <Box
                    component="img"
                    src={logo}
                    sx={{ maxWidth: 100}}
                    alt="Beauty Room Footer Dark Logo"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12} spacing={2}>
                <Box component="a" href="https://www.facebook.com/profile.php?id=100090185408118" sx={iconStyle}>
                  <FaFacebook/>
                </Box>
                {/*<Box component="a" href="https://twitter.com/MUI_hq" sx={iconStyle}>*/}
                {/*  <FaInstagram/>*/}
                {/*</Box>*/}
              </Grid>
              <Grid item xs={12} md={12}>
                <Box>
                  <Copyright />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={8} md={3}>
            <Typography variant="h6" marked="left" gutterBottom>
              Контакти
            </Typography>
            <Box sx={{ m: 0, p: 0 }}>
              <FaMapMarkerAlt /> {configuration.address}
            </Box>
            <Box sx={{ m: 0, p: 0 }}>
              <FaPhoneAlt /> {configuration.phone}
            </Box>
          </Grid>
          <Grid item xs={6} sm={8} md={3}>
            <Typography variant="h6" marked="left" gutterBottom>
              Работно време
            </Typography>
            <Box sx={{ m: 0, p: 0 }}>
              Понеделник: {configuration.working_hours.monday}
            </Box>
            <Box sx={{ m: 0, p: 0 }}>
              Вторник: {configuration.working_hours.tuesday}
            </Box>
            <Box sx={{ m: 0, p: 0 }}>
              Сряда: {configuration.working_hours.wednesday}
            </Box>
            <Box sx={{ m: 0, p: 0 }}>
              Четвъртък: {configuration.working_hours.thursday}
            </Box>
            <Box sx={{ m: 0, p: 0 }}>
              Петък: {configuration.working_hours.friday}
            </Box>
            <Box sx={{ m: 0, p: 0 }}>
              Събота: {configuration.working_hours.saturday}
            </Box>
            <Box sx={{ m: 0, p: 0 }}>
              Неделя: {configuration.working_hours.sunday}
            </Box>
          </Grid>
          <Grid item xs={6} sm={8} md={3}>
            <Typography variant="h6" marked="left" gutterBottom>
              Меню
            </Typography>
            <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
              {configuration.all_pages.map((page) => {
                return (
                  <Box key={page.key} component="li" sx={{py: 0.5}}>
                    <Link href={page.path}>{page.name}</Link>
                  </Box>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
